import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Dealer = () => {
  const location = useLocation(); // Get the current location

  // Render the button only on the home page
  if (location.pathname !== '/') {
    return null; // Do not render anything if not on the home page
  }

  return (
    <Link
      to="/contact-us"
      className="fixed bottom-5 right-5 md:bottom-10 md:right-10 lg:bottom-16 lg:right-13
                 w-[45%] sm:w-[150px] md:w-[300px] lg:w-[200px] 
                 h-[50px] sm:h-[55px] lg:h-[60px] z-20 rounded-lg font-md
                 flex items-center justify-center text-[16px] sm:text-[18px] lg:text-[20px]
                 bg-[#800000] text-white shadow-lg transition-transform transform hover:scale-105"
    >
      Apply for Dealership
    </Link>
  );
};

export default Dealer;
